<template>
  <div class="result-feedback-form">
    <v-expand-transition hide-on-leave>
      <div v-if="!showSuccess" key="1">
        <!-- rate selector -->
        <div class="rate-selector">
          <div
            class="rate-item"
            :class="{ 'is-active': currentRate >= value }"
            v-for="(value, index) in [5, 4, 3, 2, 1]"
            :key="index"
            @click="setRate(value)"
          >
            <span class="material-icons"> star_rate </span>
          </div>
        </div>
        <!-- textarea & submit button -->
        <v-expand-transition>
          <div class="text-form" v-if="currentRate !== 0">
            <div class="textarea-wrap">
              <v-textarea
                class="form-textarea"
                background-color="#161414"
                solo
                flat
                no-resize
                hide-details="auto"
                height="175"
                :label="formLabel"
                v-model="feedbackText"
                :rules="[
                  (val) =>
                    rules.censorValidator(val) || $t('error_team_abuse_word'),
                ]"
              ></v-textarea>
            </div>
            <mem-button :disabled="!canSubmit" @click="submitRate">{{
              $t("submit")
            }}</mem-button>
          </div>
        </v-expand-transition>
      </div>

      <!-- success state -->
      <div class="success-msg" v-if="showSuccess" key="2">
        <div>{{ $t("result_feedback_success") }}</div>
      </div>
    </v-expand-transition>
  </div>
</template>
<script>
import { censorValidator } from "@/common/validators";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    // stars: [
    //   { id: 0, value: 1 },
    //   { id: 1, value: 2 },
    //   { id: 2, value: 3 },
    //   { id: 3, value: 4 },
    //   { id: 4, value: 5 },
    // ],
    currentRate: 0,
    feedbackText: "",
    showSuccess: false,
    rules: {
      censorValidator,
    },
  }),
  computed: {
    formLabel() {
      if (this.currentRate <= 3) return this.$t("result_feedback_label_1");
      return this.$t("result_feedback_label_2");
    },
    canSubmit() {
      return this.rules.censorValidator(this.feedbackText);
    },
  },
  methods: {
    setRate(value) {
      // console.log("Set rate ", value);
      if (value === this.currentRate) {
        this.currentRate = 0;
        return;
      }
      this.currentRate = value;
    },
    submitRate() {
      this.showSuccess = true;
      this.$emit("submit", {
        rating: this.currentRate,
        comment: this.feedbackText,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.result-feedback-form {
  max-width: 315px;
  width: 100%;
  padding-bottom: 60px;
  //   border: 1px solid white;
  .rate-selector {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: dense;
    direction: rtl;
    gap: 4px;
    .rate-item {
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #1b1a1a;
      @include cursorPointer;
      .material-icons {
        font-size: 58px;
        color: #494848;
      }
      &:hover {
        background-color: #cf102d;
        .material-icons {
          color: #ffffff;
        }
        ~ .rate-item {
          background-color: #cf102d;
          .material-icons {
            color: #ffffff;
          }
        }
      }
    }
    .is-active {
      background-color: #cf102d;
      .material-icons {
        color: #ffffff;
      }
    }
  }
  .text-form {
    margin-top: 8px;
    .textarea-wrap {
      padding-top: 10px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: #161414;
      margin-bottom: 24px;
    }
    .form-textarea {
      font-size: 14px;
      line-height: 18px;
      ::v-deep(.v-label) {
        top: 10px;
        font-size: 14px;
        line-height: 18px;
        text-transform: none;
        overflow: visible;
        white-space: normal;
      }
      ::v-deep(textarea) {
        line-height: 18px;
      }
    }
    button {
      height: 38px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0.04em;
    }
  }
  .success-msg {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 40px;

    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }
}
</style>